.main {
  display: flex;
  padding: 0px;
}

.content-wrapper {
  width: 100%;
  min-width: min(750px, 100vw);
  max-width: calc(100vw - 145px); /* Adjust 145px based on your TOC width */
  margin-left: auto;
  padding: 0 20px;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content-inner {
  max-width: 100%;
  gap: 20px;
}

.toc {
  width: 145px;
  flex-shrink: 0;
  padding-top: 7px;
  padding-left: 7px;
  background-color: #FFF8DC;
  height: 100vh;
  position: sticky;
  top: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  min-width: 0;
  height: 100%;
}

.two-column-layout {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.main-column {
  flex: 1;
  min-width: 300px; /* Prevent columns from becoming too narrow */
}

.side-column {
  flex: 1;
  min-width: 250px;
}

.settings-section {
  margin-bottom: 20px;
    min-height: 150px; // Set a minimum height for the box  
}
.settings-caption {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-group {
  border: 1px solid #ccc;
}
.settings-row {
  display: flex;
}
.settings-label {
  flex: 1;
  font-weight: bold;
}
.settings-value {
  flex: 2;
}

.search-box {
  margin: 20px 0;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.search-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.settings-row-disabled {
  opacity: 0.38;
  background-color: rgba(0, 0, 0, 0.08);
  pointer-events: none;
}

.settings-row-disabled button {
  pointer-events: auto;
}


.specimen-history .settings-row {
    grid-template-columns: repeat(3, 1fr);
}
.specimen-history .settings-header {
  grid-template-columns: 1fr 1fr 1fr;
}

.three-columns .settings-row,
.three-columns .settings-header {
  grid-template-columns: 1fr 1fr 1fr;
}

.four-columns .settings-row,
.four-columns .settings-header {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.five-columns .settings-row,
.five-columns .settings-header {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.six-columns .settings-row,
.six-columns .settings-header {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.users .settings-row,
.users .settings-header {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.containers .settings-row,
.containers .settings-header {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.specimen-history, .rack-types, .containers, .users, .six-columns, .five-columns, .four-columns, .three-columns {
  & .settings-header {
    display: grid;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    padding: 0.5rem;
    text-align: center;
  }

    & .settings-row {
      display: grid;
      gap: 1rem;
      padding: 0.5rem;
    }
  }

.compact {
  .settings-row {
    padding: 0.25rem;
  }

  .button {
    padding: 0 12px;
    height: 28px;
    font-size: 12px;
  }
}

.center-value {
  .button {
    margin-right: 8px;
  }

  .button:last-child {
    margin-right: 0;
  }
  
  text-align: center;
}

@media (max-width: 1024px) {
  .main-column,
  .side-column {
    flex: 1 1 100%; /* Stack columns on smaller screens */
  }
}

.center {
  justify-content: center;
}

.links {
  display: flex;
  margin: 25px 0;
  flex-wrap: wrap;
  gap: 1rem;
}

.empty-section {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-style: italic;
}

.button {
  padding: 0 24px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  border: none;
  text-transform: uppercase;
  transition: all 0.2s ease;

  &.button-secondary {
    background-color: #E8DEF8;
    color: #1D192B;
    
    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
      box-shadow: none;
    }
    
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
    }
      
    &:hover {
      background-color: #D8D0E8;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12);
    }
  }

  &.button-primary {
    background-color: #6750A4;
    color: #FFFFFF;

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
      box-shadow: none;
    }
  
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
    }

    &:hover {
      background-color: #7C6EAE;
      box-shadow: 0 1px 3px rgba(0,0,0,0.3);
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 32px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h2 {
    margin: 0;
    color: #1D192B;
  }
}

.modal-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  
  &:hover {
    color: #333;
  }
}

.form-group {
  margin-bottom: 24px;
  
  label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #1D192B;
  }
  
  input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #CAC4D0;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
    
    &:focus {
      outline: none;
      border-color: #6750A4;
      box-shadow: 0 0 0 1px #6750A4;
    }

    &:disabled {
      background-color: #F5F5F5;
      color: #666;
      cursor: not-allowed;
    }
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.checkbox-group {
  display: flex;
  align-items: center;

  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    input[type="checkbox"] {
      width: auto;
      margin: 0;
      cursor: pointer;
    }
  }
}

.notification {
  padding: 12px 24px;
  margin: 16px 0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  position: relative;

  .notification-close {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: currentColor;
    opacity: 0.7;
    padding: 4px;

    &:hover {
      opacity: 1;
    }
  }

  &.notification-success {
    background-color: #E6F4EA;
    color: #1E4620;
    border: 1px solid #A8DAB5;
  }

  &.notification-error {
    background-color: #FCE8E6;
    color: #93000A;
    border: 1px solid #F5C2C7;
  }
}

.page-header {
  margin-bottom: 32px;

  .page-title {
    color: #1D192B;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    margin: 0;
    padding: 0;
  }

  .page-subtitle {
    color: #49454F;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 4px;
  }
}

// Loading related styles
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 100%;
  // padding: 32px;
  text-align: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.loading-text {
  font-size: 1.2rem;
  color: #666;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Chart Section
.chart-section {
  background: white;
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chart-section h3 {
  margin: 0 0 20px 0;
  color: #333;
}

.chart-container {
  width: 100%;
  // height: 300px;
}

// material ui styles for the select component
.select-container {
  margin: 20px 0;
}

.material-select-wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}

.material-select {
  appearance: none;
  background-color: transparent;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 250px;
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.material-select:focus {
  outline: none;
  border-color: #1976d2;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.material-select:hover {
  border-color: #666;
}

optgroup {
  font-weight: 600;
  color: #666;
}

option {
  font-weight: normal;
  color: #000;
  padding: 8px;
}

/* History tracking Section */
.history-container {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.history-header {
  display: grid;
  grid-template-columns: 200px 1fr;
  background-color: #f5f5f5;
  padding: 7px 16px;
  font-weight: 600;
  border-bottom: 1px solid #e0e0e0;
}

.history-list {
  max-height: 500px;
  overflow-y: auto;
}

.history-row {
  display: grid;
  grid-template-columns: 200px 1fr;
  padding: 2px 16px;
  border-bottom: 1px solid #e0e0e0;
}

.history-row:hover {
  background-color: #f8f8f8;
}

.history-time {
  color: #666;
  font-family: monospace;
}

.history-description {
  line-height: 1.4;

  .track-action {
    color: #007bff;
  }

  .track-date {
    color: #800000;
  }

  .track-object {
    color: #FF0000;
  }

  .track-action-by {
    color: #008000;
  }
}

// currently used for refresh button in rack history
.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: inherit;
  opacity: 0.7;
}

.icon-button:hover {
  opacity: 1;
}

.history-event {
  p {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}

.history-event code {
  background: #f5f5f5;
  padding: 2px 4px;
  border-radius: 3px;
}

.history-event blockquote {
  border-left: 3px solid #ccc;
  margin: 8px 0;
  padding-left: 1em;
  color: #666;
}

// currently used for specimen details
.specimen-details {
  padding: 1rem;
}

.specimen-field {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.specimen-field label {
  min-width: 120px;
  font-weight: bold;
}

.specimen-field .field-value {
  flex: 1;
}

.specimen-field .field-value.editable {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

// locked rack banner
.warning-banner {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
}